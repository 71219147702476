
export default
	props:
		card: Object
		size: String

	computed:
		classes: -> [@size, 'url' if @card.url]
		cornerRadius: -> 0 if @size != "small"
		imageCornerRadius: -> 0 if @size == "small"
		aspect: -> switch @size
			when "small" then 1
			when "medium" then 0.8
			when "large" then 1.25

	methods:
		playMedia: ->
			video = @$refs.media.$el.querySelector 'video'
			return unless video
			video.play()

		pauseMedia: ->
			video = @$refs.media.$el.querySelector 'video'
			return unless video
			video.pause()

