import { render, staticRenderFns } from "./card.vue?vue&type=template&id=52ef1b0f&scoped=true&lang=pug"
import script from "./card.vue?vue&type=script&lang=coffee"
export * from "./card.vue?vue&type=script&lang=coffee"
import style0 from "./card.vue?vue&type=style&index=0&id=52ef1b0f&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ef1b0f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/site/components/globals/craft-visual.coffee').default,Squircle: require('/opt/build/repo/site/components/globals/squircle/index.vue').default,BtnCraft: require('/opt/build/repo/site/components/globals/btn/craft.vue').default,LogoBtn: require('/opt/build/repo/site/components/globals/logo-btn.vue').default})
